import React from 'react'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="copyright">
        <li>&copy; Shailaja Vasudevan 2021</li>
      </ul>
      <div className="credit">
      Photo by <a href="https://burst.shopify.com/@sarahpflugphoto?utm_campaign=photo_credit&amp;utm_content=Free+Sunny+Overhead+Of+Desk+Photo+%E2%80%94+High+Res+Pictures&amp;utm_medium=referral&amp;utm_source=credit">Sarah Pflug</a> from <a href="https://burst.shopify.com/api-tech?utm_campaign=photo_credit&amp;utm_content=Free+Sunny+Overhead+Of+Desk+Photo+%E2%80%94+High+Res+Pictures&amp;utm_medium=referral&amp;utm_source=credit">Burst</a>
      </div>
    </div>
  </footer>
)

export default Footer
